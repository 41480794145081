<template>
  <v-row>
    <v-col cols="12"> 
      <date-piker></date-piker> 
    </v-col>
    <v-col cols="12">
      <v-row class="match-height">
        <v-col cols="12" sm="3">
          <statistics-card-vertical
            :change="totalProfit.change"
            :color="totalProfit.color"
            :icon="totalProfit.icon"
            :statistics="totalProfit.statistics"
            :stat-title="totalProfit.statTitle"
            :subtitle="totalProfit.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col cols="12" sm="3">
          <statistics-card-vertical
            :change="totalSales.change"
            :color="totalSales.color"
            :icon="totalSales.icon"
            :statistics="totalSales.statistics"
            :stat-title="totalSales.statTitle"
            :subtitle="totalSales.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col cols="12" sm="3">
          <statistics-card-vertical
            :change="newProject.change"
            :color="newProject.color"
            :icon="newProject.icon"
            :statistics="newProject.statistics"
            :stat-title="newProject.statTitle"
            :subtitle="newProject.subtitle"
          ></statistics-card-vertical>
        </v-col>

        <v-col cols="12" sm="3">
          <statistics-card-vertical
            :change="salesQueries.change"
            :color="salesQueries.color"
            :icon="salesQueries.icon"
            :statistics="salesQueries.statistics"
            :stat-title="salesQueries.statTitle"
            :subtitle="salesQueries.subtitle"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="4">
      <dashboard-card-sales-by-countries></dashboard-card-sales-by-countries>
    </v-col>
    <v-col cols="12" md="8">
      <dashboard-card-deposit-and-withdraw></dashboard-card-deposit-and-withdraw>
    </v-col>
    <v-col cols="12">
      <dashboard-datatable></dashboard-datatable>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
import DashboardCongratulationJohn from '@/views/dashboard/DashboardCongratulationJohn.vue'
import DashboardStatisticsCard from '@/views/dashboard/DashboardStatisticsCard.vue'
import DashboardCardTotalEarning from '@/views/dashboard/DashboardCardTotalEarning.vue'
import DashboardCardDepositAndWithdraw from '@/views/dashboard/DashboardCardDepositAndWithdraw.vue'
import DashboardCardSalesByCountries from '@/views/dashboard/DashboardCardSalesByCountries.vue'
import DashboardWeeklyOverview from '@/views/dashboard/DashboardWeeklyOverview.vue'
import DashboardDatatable from '@/views/dashboard/DashboardDatatable.vue'
import DialogPrueba from '@/views/dashboard/Dialog.vue'
import DataTableExample from '@/views/dashboard/DataTableExample.vue'
import DatePiker from '@/views/dashboard/components/DatePicher.vue'
export default {
  components: {
    StatisticsCardVertical,
    DashboardCongratulationJohn,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,
    DashboardCardDepositAndWithdraw,
    DashboardCardSalesByCountries,
    DashboardWeeklyOverview,
    DashboardDatatable,
    DialogPrueba,
    DataTableExample,
    DatePiker,
  },
  setup() {
    const totalProfit = {
      statTitle: 'Ventas general',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Sin 10%: $9,384.27',
      statistics: '$9,953.25',
      change: '',
    }

    const totalSales = {
      statTitle: 'Comandas pagadas',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Por Pagar: $0.00',
      statistics: '$9,953.25',
      change: '',
    }

    // vertical card options
    const newProject = {
      statTitle: 'Servicio',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: '% Evitado: $369.45',
      statistics: '$568.9',
      change: '',
    }

    const salesQueries = {
      statTitle: 'Balance General',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Pagado: $5,568.39',
      statistics: '$5,568.39',
      change: '',
    }

    return {
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
    }
  },
}
</script>
