
<template>
  
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted" 
            persistent-hint
            :prepend-icon="icons.mdiCalendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker
          v-model="date"
          no-title
          color="primary"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
      
    
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, watch } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'

export default {
  setup() {
    // const vm = getCurrentInstance().proxy
    const date = ref(new Date().toISOString().substr(0, 10))
    const menu1 = ref(false)
    const menu2 = ref(false)

    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}-${month}-${year}`
    }

    let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

    const parseDate = dates => {
      if (!dates) return null
      const [month, day, year] = dates.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }

    const computedDateFormatted = computed(() => formatDate(date.value))

    watch(date, () => {
      dateFormatted = formatDate(date.value)
    })

    return {
      date,
      dateFormatted,
      menu1,
      menu2,
      computedDateFormatted,
      parseDate,
      formatDate,

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
